import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Logo = styled.img`
  width: 200px;
  @media (max-width: 768px) {
    width: 140px;
  }
`;

const Header = (props) => {
  const alt = `Location Utilitaire ${props.ville}`;

  return (
    <header align="center">
      <Link to="/" replace>
        <Logo src="/logo.png" alt={alt} />
      </Link>
    </header>
  );
};

export default Header;
